<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
        >
          {{ label }}
        </h4>

        <div class="tab-danger pt-3">
          <!-- Nav Tabs -->
          <ul
            class="
              nav nav-tabs nav-line-tabs nav-stretch
              fs-6
              border-0
              fw-bolder
            "
            role="tablist"
          >
            <li class="nav-item">
              <a
                href="#tab-arabic"
                class="nav-link text-active-primary"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="true"
                :class="$i18n.locale == 'ar' ? 'font-size-15 active show' : ''"
              >
                {{ $t("app.arabic") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-english"
                class="nav-link text-active-primary"
                role="tab"
                data-bs-toggle="tab"
                aria-selected="false"
                :class="$i18n.locale === 'en' ? 'font-size-15 active show' : ''"
              >
                {{ $t("app.english") }}
              </a>
            </li>
          </ul>

          <!-- Tab Panels -->
          <div class="tab-content">
            <div
              role="tabpanel"
              class="tab-pane fade pt-3"
              :class="$i18n.locale == 'ar' ? 'active show' : ''"
              id="tab-arabic"
            >
              <!-- Title -->
              <div v-if="hasTitle" class="form-group col-12">
                <label
                  for="inputAR1"
                  class="col-form-label"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
                >
                  {{ labelTitle }}
                </label>
                <input
                  id="inputAR1"
                  type="text"
                  class="form-control"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
                  v-model="row.title_ar"
                  v-on:input="onFormChange"
                />
              </div>
              <!-- End Title -->

              <!-- Body -->
              <div v-if="hasBody" class="form-group col-12">
                <label
                  for="editorAR1"
                  class="col-form-label"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
                >
                  {{ labelBody }}
                </label>
                <div v-if="$i18n.locale == 'en'" class="col-12">
                  <br /><br />
                </div>
                <editor
                  id="editorAR1"
                  :style="$i18n.locale === 'ar' ? 'font-size-15' : 'float-left'"
                  v-model="row.body_ar"
                  @change="onFormChange"
                  :api-key="editor.api_key"
                  :init="{
                    height: 300,
                    menubar: editor.menubar,
                    plugins: editor.plugins,
                    toolbar: editor.toolbar,
                    directionality: 'rtl',
                  }"
                />
              </div>
              <!-- End Body -->

              <!-- Address -->
              <div v-if="hasAddress" class="form-group col-12">
                <label
                  for="inputARMO1"
                  class="col-form-label"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
                >
                  {{ labelAddress }}
                </label>
                <input
                  id="inputARMO1"
                  type="text"
                  class="form-control"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
                  v-model="row.address_ar"
                  v-on:input="onFormChange"
                />
              </div>
              <!-- End Address -->
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-english"
            >
              <!-- Title -->
              <div v-if="hasTitle" class="form-group col-12">
                <label
                  for="inputEN1"
                  class="col-form-label"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
                >
                  {{ labelTitle }}
                </label>
                <input
                  id="inputEN1"
                  type="text"
                  class="form-control"
                  style="direction: ltr"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
                  v-model="row.title_en"
                  v-on:input="onFormChange"
                />
              </div>
              <!-- End Title -->

              <!-- Body -->
              <div v-if="hasBody" class="form-group col-12">
                <label
                  for="editorEN1"
                  class="col-form-label"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
                >
                  {{ labelBody }}
                </label>
                <div v-if="$i18n.locale == 'en'" class="col-12">
                  <br /><br />
                </div>
                <editor
                  id="editorEN1"
                  v-model="row.body_en"
                  @change="onFormChange"
                  :api-key="editor.api_key"
                  :init="{
                    height: 300,
                    menubar: editor.menubar,
                    plugins: editor.plugins,
                    toolbar: editor.toolbar,
                  }"
                />
              </div>
              <!-- End Body -->

              <!-- Address -->
              <div v-if="hasAddress" class="form-group col-12">
                <label
                  for="inputENMO1"
                  class="col-form-label"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
                >
                  {{ labelAddress }}
                </label>
                <input
                  id="inputENMO1"
                  type="text"
                  class="form-control"
                  style="direction: ltr"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
                  v-model="row.address_en"
                  v-on:input="onFormChange"
                />
              </div>
              <!-- End Address -->
            </div>
          </div>
          <!-- End Tab Panels -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormElements",
  props: [
    "label",
    "labelTitle",
    "labelPosition",
    "labelAddress",
    "labelBody",

    "hasTitle",
    "hasAddress",
    "hasBody",

    "title_ar",
    "title_en",

    "address_ar",
    "address_en",

    "body_ar",
    "body_en",
  ],
  data() {
    return {
      row: {
        title_ar: this.$props.title_ar,
        title_en: this.$props.title_en,

        address_ar: this.$props.address_ar,
        address_en: this.$props.address_en,

        body_ar: this.$props.body_ar,
        body_en: this.$props.body_en,
      },

      editor: {
        api_key: window.editor_apiKey,
        menubar: window.editor_menubar,
        plugins: window.editor_plugins,
        toolbar: window.editor_toolbar,
      },
    };
  },
  methods: {
    onFormChange() {
      console.log(this.row);
      const data = {
        title_ar: this.row.title_ar,
        title_en: this.row.title_en,

        address_ar: this.row.address_ar,
        address_en: this.row.address_en,

        body_ar: this.row.body_ar,
        body_en: this.row.body_en,
      };
      this.$emit("formChange", data);
    },
  },
});
</script>
