<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : 'txt-left'"
      >
        {{ labelImage }}
      </h4>
      <div class="mt-3">
        <p>
          <img
            v-if="row.image_preview"
            :src="row.image_preview"
            style="max-width: 100%; max-height: 200px"
          />
        </p>
        <button
          type="button"
          class="btn btn-primary waves-effect waves-light w-sm"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
          @click="openFile"
        >
          <i class="mdi mdi-upload font-size-16"></i>
          {{ upload_btn }}
        </button>

        <input
          type="file"
          class="form-control hidden"
          ref="myDropify"
          v-on:change="onImageChange"
          accept="image/*"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardImage",
  props: ["labelImage", "image_base64", "image_preview", "upload_icon"],
  data() {
    return {
      row: {
        image_base64: this.$props.image_base64,
        image_preview: this.$props.image_preview,
      },
      upload_btn: "",
    };
  },
  mounted() {
    if (this.$props.upload_icon) {
      if (this.row.image_preview) {
        this.upload_btn = this.$t("app.change_upload_icon");
      } else {
        this.upload_btn = this.$t("app.upload_icon");
      }
    } else {
      if (this.row.image_preview) {
        this.upload_btn = this.$t("app.change_upload");
      } else {
        this.upload_btn = this.$t("app.upload");
      }
    }
  },
  methods: {
    onFileChange() {
      const data = {
        image_preview: this.row.image_preview,
        image_base64: this.row.image_base64,
      };
      this.$emit("imageChange", data);
    },

    openFile() {
      this.$refs.myDropify.click();
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.row.image_preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image_base64 = e.target.result;
        this.onFileChange();
      };
    },
  },
});
</script>
