
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardStatus",
  props: [
    "status",
    "hasShowInHeader",
    "hasShowInFooter",
    "hasShowInSupport",
    "show_in_header",
    "show_in_footer",
    "show_in_support",
  ],
  data() {
    return {
      row: {
        status: this.$props.status ?? false,
        show_in_header: this.$props.show_in_header ?? false,
        show_in_footer: this.$props.show_in_footer ?? false,
        show_in_support: this.$props.show_in_support ?? false,
      },
    };
  },
  methods: {
    onStatusChange() {
      const data = {
        status: this.row.status,
        show_in_header: this.row.show_in_header,
        show_in_footer: this.row.show_in_footer,
        show_in_support: this.row.show_in_support,
      };
      this.$emit("statusChange", data);
    },

    onRadioChange() {
      this.row.status = this.row.status ? 0 : 1;
      this.onStatusChange();
    },

    onHeaderChange() {
      this.row.show_in_header = this.row.show_in_header ? 0 : 1;
      this.onStatusChange();
    },

    onFooterChange() {
      this.row.show_in_footer = this.row.show_in_footer ? 0 : 1;
      this.onStatusChange();
    },

    onSupportChange() {
      this.row.show_in_support = this.row.show_in_support ? 0 : 1;
      this.onStatusChange();
    },
  },
});
