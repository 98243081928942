<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : 'txt-left'"
      >
        {{ label }}
      </h4>
      <div class="col-12 pd-0" style="padding: 0">
        <input
          id="input"
          class="form-control"
          :type="type"
          :placeholder="label"
          v-model="row.input"
          @paste="onPaste"
          v-on:input="onChange"
        />
        <!--  @keydown.space.prevent  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardInput",
  props: ["bus", "label", "type", "input"],
  data() {
    return {
      row: {
        input: this.$props.input,
      },
    };
  },
  created() {
    if (this.bus != undefined) {
      this.bus.on("input_changed", (input) => {
        this.row.input = input.replaceAll(",", "");
      });
    }
  },
  methods: {
    onChange() {
      const data = {
        input: this.row.input,
      };
      this.$emit("inputChange", data);
    },
    onPaste() {
      let str = this.row.input;
      this.preventSpace(str);
    },
    preventSpace(str) {
      if (!str) {
        let str = this.row.input;
        this.row.input = str.replace(/\s+/g, "-");
      } else {
        this.row.input = str.replace(/\s+/g, "-");
      }
    },
  },
};
</script>
